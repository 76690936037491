<template>
	<div class="player-container">
		<div id="dplayer" class="player" ref="dplayer"></div>
		<div v-if="player.status > 0" :class="['status', 'status-' + player.status]"></div>
	</div>
</template>

<script>
import moment from 'moment';
import { PLAYER_STATUS } from '@/utils/constants.js';

var reinitTimer = null;
var waitingTimer = null;
export default {
	name: 'wsPlayer',
	components: {},
	data() {
		return {
			player: {
				instance: null,
				tryCount: 20,
				status: PLAYER_STATUS.LOADING
			},
			match_start: 0
		};
	},
	methods: {
		initPlayer(match) {
			if (reinitTimer) {
				clearTimeout(reinitTimer);
			}
			if (this.player.instance) {
				try {
					this.player.instance.dispose();
				} catch (e) {}
			}
			this.match_start = match.match_time_int || match.start_time_int
			this.player.status = undefined;
			console.log(match.state,99999999999)
			console.log(match.url)
			if (/\.mp4/i.test(match.url)) {
				this.initReplyPlayer(match);
			} else if (match.state == -1) {
				this.player.status = PLAYER_STATUS.ENDED;
				return;
			} else if (match.state == 0 && this.match_start - moment().unix() > 3600) {
				this.player.status = PLAYER_STATUS.NOSTART;
				return;
			} else if (/\.m3u8|\.flv/.test(match.url)) {
				this.initLivePlayer(match);
			}else if(match.tournament_id || match.url == undefined) {
                this.player.status = PLAYER_STATUS.OFFSHELF;
			}
			 else if (!match.tournament_id) {
				this.player.status = PLAYER_STATUS.NOLIVING;
			} else {
				this.player.status = PLAYER_STATUS.NOSIGN;
			}
		},
		initReplyPlayer(match) {
			if (this.player.instance) {
				try {
					this.player.instance.dispose();
				} catch (e) {}
			}
			new Aliplayer(
				{
					id: 'dplayer',
					cover: require('../assets/img/default_poster.png'),
					source: match.url,
					width: '100%',
					height: '100%',
					autoplay: true,
					rePlay: true,
					skinLayout: [
						{ name: 'bigPlayButton', align: 'blabs', x: 30, y: 80 },
						{
							name: 'H5Loading',
							align: 'cc'
						},
						{ name: 'tooltip', align: 'blabs', x: 0, y: 56 },
						{ name: 'thumbnail' },
						{
							name: 'controlBar',
							align: 'blabs',
							x: 0,
							y: 0,
							children: [
								{ name: 'progress', align: 'blabs', x: 0, y: 44 },
								{ name: 'playButton', align: 'tl', x: 15, y: 12 },
								{ name: 'timeDisplay', align: 'tl', x: 10, y: 7 },
								{ name: 'fullScreenButton', align: 'tr', x: 10, y: 12 },
								{ name: 'volume', align: 'tr', x: 5, y: 10 }
							]
						}
					]
				},
				player => {
					this.player.instance = player;
				}
			);
		},
		initLivePlayer(match) {
			if (this.player.instance) {
				try {
					this.player.instance.dispose();
				} catch (e) {}
			}
			const config = {
				id: 'dplayer',
				cover: require('../assets/img/default_poster.png'),
				source: match.url,
				width: '100%',
				height: '100%',
				autoplay: false,
				isLive: true,
				playsinline: true,
				useH5Prism: true,
				diagnosisButtonVisible: false,
				controlBarVisibility: 'hover',
				liveRetry: 3,
				skinLayout: [
					{ name: 'bigPlayButton', align: 'blabs', x: 30, y: 80 },
					{
						name: 'controlBar',
						align: 'blabs',
						x: 0,
						y: 0,
						children: [
							{ name: 'liveDisplay', align: 'tlabs', x: 15, y: 6 },
							{ name: 'fullScreenButton', align: 'tr', x: 10, y: 10 },
							{ name: 'volume', align: 'tr', x: 5, y: 10 }
						]
					}
				]
			};
			new Aliplayer(config, player => {
				[
					'ready',
					'autoplay',
					'play',
					'pause',
					'canplay',
					'playing',
					'ended',
					'liveStreamStop',
					'onM3u8Retry',
					'hideBar',
					'showBar',
					'waiting',
					'timeupdate',
					'snapshoted',
					'requestFullScreen',
					'cancelFullScreen',
					'error',
					'startSeek',
					'completeSeek'
				].forEach(eName => {
					player.on(eName, e => {
						switch (e.type) {
							case 'ready':
								if (player.getStatus() != 'playing') {
									player.play();
								}
								break;
							case 'waiting':
								this.player.status = PLAYER_STATUS.WAITING;
								break;
							case 'playing':
								this.player.tryCount = 15;
								this.player.status = PLAYER_STATUS.PLAYING;
								break;
							case 'error':
								console.info('player.reInitPlayer', this.player.tryCount);
								const tryCount = --this.player.tryCount;
								let delay = 0;
								if (tryCount > 10) {
									delay =  500;
								} else if (tryCount > 5) {
									this.player.status = PLAYER_STATUS.ERROR;
									delay = 2000;
								} else if (tryCount > 0) {
									this.player.status = PLAYER_STATUS.ERROR;
									delay = 20000;
								} else {
									this.player.status = LAYER_STATUS.ERROR;
									delay = 60000;
								}
								if (this.match_start - moment().unix() > 60){
									this.player.status = PLAYER_STATUS.NOSTART
									delay = 120000;
								}
								reinitTimer = setTimeout(() => {
									this.initLivePlayer(match);
								}, delay);
								break;
						}
						console.info('player.event.' + eName, e);
					});
					this.player.instance = player;
				});
			});
		}
	}
};
</script>

<style lang="less">
.player-container,
.dplayer {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: #2b2b2b;
	.player {
		z-index: 1;
	}
	.status {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		// bottom: 50px;
		height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-color: #2b2b2b;
		&.status-1 {
			background-image: url(../assets/img/state_error.png);
			background-size: 204px 234px;
		}
		&.status-2 {
			background-image: url(../assets/img/state_0.png);
			background-size: 204px 234px;
		}
		&.status-3 {
			background-image: url(../assets/img/state_error.png);
			background-size: 204px 234px;
		}
		&.status-4 {
			background-image: url(../assets/img/state_-1.png);
			background-size: 204px 234px;
		}
		&.status-5 {
			background-image: url(../assets/img/state_2048.png);
			background-size: 204px 234px;
		}
		&.status-6 {
			background-color: transparent;
			background-image: url(../assets/img/loading.gif);
			background-position: 40px 40px;
			background-size: 40px 40px;
		}
		&.status-7 {
			background-color: transparent;
			background-image: url(../assets/img/state_1024.png);
			background-position: center center;
			background-size: 340px 190px;
		}
	}
}
</style>
